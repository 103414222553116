<template>
	<div class="flex justify-center mb-4 m-lg:mt-10">
		<div class="flex px-3 py-0.5 rounded-xl w-5/12 border border-white-0 shadow-xl
		            bg-gradient-to-b from-white-0 to-white-600
					m-lg:w-11/12 ">
			<img src="~/assets/icons/magnifier.svg" alt="Search Icon" class="w-5 h-5 self-center" width="15" height="15">
			<label for="search-input" class="sr-only">Search Minecraft Servers</label>
			<input
					v-model="search"
					placeholder="Search..."
					type="text"
					@keyup="keyUp"
					autofocus
					class="p-2 flex-1 outline-none font-normal bg-transparent w-full"
			>
			<button type="submit" @click.prevent="triggerSearch"
			        class="bg-white-0 px-4 rounded-lg text-sm h-3/4 self-center border border-white-700 hover:bg-gray-200">
				Search
			</button>
		</div>
	</div>
</template>

<script setup>
const search = ref("");

function keyUp(event) {
	if (event.key === "Enter") {
		triggerSearch();
	}
}

function triggerSearch() {
	// Remove focus state from button
	document.activeElement.blur();
	if (search.value) {
		const searchUrl = encodeURIComponent(search.value.trim()).replace(/ |%20/g, "+");
		useRouter().push(`/search/${searchUrl}`)
	}
}
</script>
